import type { FormKitNode } from '@formkit/core'
import { FieldValueType, type INodeField } from '@manager'

export const useFieldCast = (fieldNode: {
  valueType: INodeField['valueType']
}) => {
  const dayjs = useDayjs()

  const cast = (value: unknown, defaultValue?: unknown) => {
    let newValue = parseValue(value) ?? parseValue(defaultValue)

    switch (fieldNode.valueType) {
      case FieldValueType.DECIMAL:
      case FieldValueType.INTEGER:
      case FieldValueType.PERCENTAGE:
      case FieldValueType.CURRENCY: {
        if (newValue !== null) {
          newValue = Number(newValue)
        }
        break
      }
      case FieldValueType.BOOLEAN: {
        if (newValue === null) {
          break
        }

        if (newValue === 'True') {
          newValue = true
        } else if (newValue === 'False') {
          newValue = false
        } else {
          newValue = Boolean(newValue)
        }

        break
      }
      case FieldValueType.DATE: {
        if (newValue !== null) {
          newValue = new Date(newValue)
          break
        }
      }
    }

    return newValue
  }

  const castPlugin = (node: FormKitNode) => {
    node.hook.input((value, next) => {
      return next(cast(value))
    })
  }

  return {
    cast,
    castPlugin,
  }
}

function parseValue(v: unknown): unknown {
  if (v === '' || v === undefined || v === null) return null
  return v
}
